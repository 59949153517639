<!-- eslint-disable vue/no-v-html -->
<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <form-panel :title="$t('labels.general_settings')">
            <form-three-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.name"
                        v-validate="'required|max:255|space'"
                        name="name"
                        label="labels.name"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-slot:col3>
                    <checkbox-input
                        v-model="form.isActive"
                        label="labels.active"
                        name="isActive"
                    ></checkbox-input>
                </template>
                <template v-slot:col2>
                    <select-input
                        v-model="form.bannerType"
                        v-validate="'required'"
                        class="mt-2"
                        name="bannerType"
                        :items="[
                            {
                                value: 'restaurant_list',
                                title: $t('labels.banner_type_restaurant_list'),
                            },
                            {
                                value: 'restaurant_page',
                                title: $t('labels.banner_type_restaurant_page'),
                            },
                            {
                                value: 'custom',
                                title: $t('labels.banner_type_custom'),
                            },
                        ]"
                        :error-messages="errors.collect('bannerType')"
                        :data-vv-as="$t('labels.banner_type')"
                        label="labels.banner_type"
                    ></select-input>
                </template>
            </form-three-col-row>

            <form-one-col-row>
                <textarea-input
                    v-model="form.expression"
                    v-validate="'required|max:255|space'"
                    name="expression"
                    label="labels.expression"
                    :error-messages="errors.collect('expression')"
                    :data-vv-as="$t('labels.expression')"
                    :full-width="false"
                ></textarea-input>

                <v-card
                    class="mt-2 pa-2"
                    outlined
                    v-html="$t('texts.banner_expression_tips')"
                ></v-card>

                <text-input
                    v-if="form.bannerType === 'custom'"
                    v-model="form.link"
                    v-validate="'required|max:255|space'"
                    class="mt-2"
                    name="link"
                    label="labels.link"
                    :error-messages="errors.collect('link')"
                    :data-vv-as="$t('labels.link')"
                ></text-input>
            </form-one-col-row>
        </form-panel>

        <form-panel :title="$t('labels.banner')">
            <form-two-col-row>
                <template v-slot:col2>
                    <textarea-input
                        v-model="form.bannerTitle"
                        v-validate="'required|space'"
                        name="bannerTitle"
                        label="labels.title"
                        :error-messages="errors.collect('bannerTitle')"
                        :data-vv-as="$t('labels.title')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerDescription"
                        v-validate="'space'"
                        name="bannerDescription"
                        label="labels.description"
                        :error-messages="errors.collect('bannerDescription')"
                        :data-vv-as="$t('labels.description')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerBgColor"
                        v-validate="colorValidator(form.bannerBgColor)"
                        name="bannerBgColor"
                        label="labels.banner_bg_color"
                        :error-messages="errors.collect('bannerBgColor')"
                        :data-vv-as="$t('labels.banner_bg_color')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerTextColor"
                        v-validate="colorValidator(form.bannerTextColor)"
                        name="bannerTextColor"
                        label="labels.banner_text_color"
                        :error-messages="errors.collect('bannerTextColor')"
                        :data-vv-as="$t('labels.banner_text_color')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerButtonText"
                        v-validate="'required|space'"
                        name="bannerButtonText"
                        label="labels.banner_button_text"
                        :error-messages="errors.collect('bannerButtonText')"
                        :data-vv-as="$t('labels.banner_button_text')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerButtonBgColor"
                        v-validate="colorValidator(form.bannerButtonBgColor)"
                        name="bannerButtonBgColor"
                        label="labels.banner_button_bg_color"
                        :error-messages="errors.collect('bannerButtonBgColor')"
                        :data-vv-as="$t('labels.banner_button_bg_color')"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.bannerButtonTextColor"
                        v-validate="colorValidator(form.bannerButtonBgColor)"
                        name="bannerButtonTextColor"
                        label="labels.banner_button_text_color"
                        :error-messages="
                            errors.collect('bannerButtonTextColor')
                        "
                        :data-vv-as="$t('labels.banner_button_text_color')"
                        :full-width="false"
                    ></textarea-input>
                </template>

                <template v-slot:col1>
                    <v-input
                        v-model="form.bannerImageMedia"
                        v-validate="'required'"
                        hide-details="auto"
                        name="bannerImageMedia"
                        :data-vv-as="$t('labels.banner_image')"
                        :error-messages="errors.collect('bannerImageMedia')"
                    >
                        <div style="width: 100%">
                            <image-editor
                                v-model="form"
                                :primary-size="{ width: 230, height: 160 }"
                                :quality="0.9"
                                name="bannerImageMedia"
                                primary-key="bannerImageMedia"
                                primary-label="labels.banner_image"
                                :full-width="false"
                            ></image-editor>
                        </div>
                    </v-input>

                    <div class="mt-8">
                        {{ $t('labels.banner_preview') }}
                        <v-card
                            class="rounded-lg overflow-hidden d-flex justify-space-between"
                            :style="`height:158px;background-color:${form.bannerBgColor}; color:${form.bannerTextColor}`"
                        >
                            <div
                                class="py-4 px-7 d-flex justify-space-between flex-column"
                            >
                                <div>
                                    <div class="text-h6 font-weight-bold">
                                        {{ form.bannerTitle }}
                                    </div>
                                    <div>{{ form.bannerDescription }}</div>
                                </div>
                                <div>
                                    <div
                                        class="py-1 d-inline-block px-4 rounded-pill text-body-1 font-weight-bold"
                                        :style="`background-color:${form.bannerButtonBgColor}; color:${form.bannerButtonTextColor}`"
                                    >
                                        {{ form.bannerButtonText }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    form.bannerImageMedia &&
                                    form.bannerImageMedia.public_url
                                "
                            >
                                <img
                                    style="height: 100%"
                                    :src="form.bannerImageMedia.public_url"
                                />
                            </div>
                        </v-card>
                    </div>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel :title="$t('labels.banner_landing_page')">
            <text-input
                v-model="form.landingPageTitle"
                v-validate="'required|space'"
                class="mt-2"
                name="landingPageTitle"
                label="labels.banner_landing_page_title"
                :error-messages="errors.collect('landingPageTitle')"
                :data-vv-as="$t('labels.banner_landing_page_title')"
            ></text-input>

            <text-input
                v-model="form.landingPageDescription"
                v-validate="'required|space'"
                class="mt-2"
                name="landingPageDescription"
                label="labels.banner_landing_page_description"
                :error-messages="errors.collect('landingPageDescription')"
                :data-vv-as="$t('labels.banner_landing_page_description')"
            ></text-input>

            <entity-select-input
                v-if="form.bannerType !== 'custom'"
                v-model="form.restaurants"
                v-validate="'required'"
                class="mt-2"
                label="labels.restaurants"
                :data-vv-as="$t('labels.restaurants')"
                :entity="selectApiClientType.RESTAURANT"
                name="restaurants"
                autocomplete
                :error-messages="errors.collect('restaurants')"
                multi-select
                translate-items
                use-id-field
            ></entity-select-input>
        </form-panel>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import RestapiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormBase from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ImageEditor from '@/components/pages/meals/ImageEditor'
import SelectInput from '@/components/form/Inputs/SelectInput.vue'
import FormThreeColRow from '@/components/form/FormThreeColRow.vue'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput.vue'

export default {
    components: {
        EntitySelectInput,
        FormThreeColRow,
        SelectInput,
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TextareaInput,
        CheckboxInput,
        ImageEditor,
    },
    mixins: [FormBase],
    data() {
        return {
            api: RestapiType.PROMO_BANNERS,
            mapper: MapperType.PROMO_BANNERS_FORM,
            form: {
                name: null,
                expression: null,
                link: null,
                banner: '',
                isActive: true,
                bannerImageMedia: null,
                description: null,
                bannerType: null,
                restaurants: [],
            },
            entity: 'labels.promo_banner',
            editRoute: routeType.PROMO_BANNERS_EDIT,
            listRoute: routeType.PROMO_BANNERS_LIST,
        }
    },
    watch: {
        'form.restaurants'() {
            if (
                this.form.bannerType === 'restaurant_page' &&
                this.form.restaurants.length > 1
            ) {
                this.form.restaurants = [this.form.restaurants.pop()]
            }
        },
    },
    methods: {
        colorValidator(value) {
            console.log('colorValidator', value)
            return {
                required: true,
                regex: /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/,
            }
        },
    },
}
</script>
